import React from 'react'
import { AppText } from '../Constants'
import { FaGooglePlay } from 'react-icons/fa'
//import { chamadaspy, wave } from '../assets'
import { ssmafia, sschild, ssspy } from '../assets'
import { textmafia, textchild, textspy } from '../assets'

let urlMafia = 'https://play.google.com/store/apps/details?id=com.captainlobster.mafiaescape'
let urlChild = 'https://play.google.com/store/apps/details?id=com.captainlobster.childhoodnightmare'
let urlSpy = 'https://captain-lobster.itch.io/spy-hunting'

const Games = () => {
  return (
    <div name="games" className='w-full bg-gradient-to-b from-gray-900 via-gray-900 to-black text-white md:h-screen'>
      <div className='max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full'>
       <div className='pb-8'>
        <p className='text-4xl font-bold border-b-4 border-gray-500'>Games</p>
        
       </div>
       <div className='text-xl mt-6 pb-6'>
        <p>{AppText.gamesDescription}</p>
        <p>{AppText.gamePurpose}</p>
       </div>
       <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0'>
        <div className='group shadow-md shadow-gray-400 rounded-lg duration-200 hover:scale-105'>
          <img src={ssmafia} alt='SSMafia' className='rounded-md h-[220px]'/>
          <div>
            <img src={textmafia} alt='LogoMafia' className='h-20 mx-auto mt-[-15px]'/>
          </div>
          <div>
            <button onClick={()=> window.open(urlMafia, '_blank')} className='text-white px-6 py-3 my-2 flex items-center rounded-md
             bg-gradient-to-r from-orange-400 to-orange-500 cursor-pointer mx-auto'>Download<FaGooglePlay size={20} className='ml-1'/></button>
          </div>
        </div>
        <div className='group shadow-md shadow-gray-400 rounded-lg duration-200 hover:scale-105'>
          <img src={sschild} alt='SSChild' className='rounded-md h-[220px]'/>
          <div>
            <img src={textchild} alt='LogoChild' className='h-20 mx-auto mt-[-15px]'/>
          </div>
          <div>
            <button onClick={()=> window.open(urlChild, '_blank')} className='text-white px-6 py-3 my-2 flex items-center rounded-md
             bg-gradient-to-r from-orange-400 to-orange-500 cursor-pointer mx-auto'>Download<FaGooglePlay size={20} className='ml-1'/></button>
          </div>
        </div>
        <div className='group shadow-md shadow-gray-400 rounded-lg duration-200 hover:scale-105'>
          <img src={ssspy} alt='SSSpy' className='rounded-md h-[220px]'/>
          <div>
            <img src={textspy} alt='LogoSpy' className='h-20 mx-auto mt-[-15px]'/>
          </div>
          <div>
            <button onClick={()=> window.open(urlSpy, '_blank')} className='text-white px-6 py-3 my-2 flex items-center rounded-md
             bg-gradient-to-r from-orange-400 to-orange-500 cursor-pointer mx-auto'>Download<FaGooglePlay size={20} className='ml-1'/></button>
          </div>
        </div>
       </div>
      </div>
    </div>
  )
}

export default Games