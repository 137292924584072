import React from 'react'
import { AppText } from '../Constants'

const Contact = () => {
  return (
    <div name="contact" className='w-full h-screen bg-gradient-to-b from-black via-black to-gray-900 text-white p-4'>
      <div className='flex flex-col p-4 justify-center max-w-screen-xl mx-auto h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold border-b-4 border-gray-500'>{AppText.Contact}</p>
        </div>
        <div className='text-xl mt-6 pb-6'>
          <p>{AppText.submitform}</p>
        </div>
        <div className='flex justify-center items-center'>
          <form action='https://getform.io/f/6b8be32b-a47b-4952-8d1d-33148b08b16f' method='POST' className='flex flex-col w-full md:w-1/2'>
            <input type='text' name='name' placeholder='Enter your name'
             className='p-2 bg-transparent border-2 rounded-md text-white focus:outline-none'/>
             <input type='text' name='email' placeholder='Enter your email'
             className='my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none'/>
             <textarea name='message' placeholder='Enter your message'  rows='10' className='p-2 bg-transparent border-2 rounded-md 
             text-white focus:outline-none'/>
             <button className='text-white bg-gradient-to-b from-orange-500 to-orange-400 px-6 py-3 rounded-md 
             my-8 mx-auto flex items-center hover:scale-110 duration-300'>
              Let's talk
             </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact