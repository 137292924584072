import React from 'react'
import './../../css/Diary.css'
//import ToWriteBox from './ToWriteBox'
import Post from './Post'
import { ssmafia, sschild, ssspy } from '../../assets'
import { FaArrowAltCircleLeft } from 'react-icons/fa'
import { Button } from '@mui/material'
//import {db} from './../../firebase'
//import {collection, getDocs} from 'firebase/firestore'

const Feed = () => {
    //Firebase Connection
    // const [postList, setPosts] = useState([]);
    // const postsCollectionsRef = collection(db, "posts");

    // useEffect(() => {
    //     const getPostsList = async() =>{
    //         //Read the data

    //         //Set the posts list
    //         try{
    //             const data = await getDocs(postsCollectionsRef);
    //             const filteredData = data.docs.map((doc) => ({...doc.data(), id: doc.id,}))
    //             setPosts(filteredData);
    //         } catch (err){
    //             console.error(err);
    //         }

    //     };

    //     getPostsList();
    // }, [postsCollectionsRef]);

    return (
        <div className='feed flex-[0.4] min-w-fit border-r-[1px] border-l-[1px] border-gray-700 overflow-y-scroll'>
            <div className='feed_header flex justify-between'>
                <h2>Captain's Diary</h2>
                <div className='md:hidden'>
                    <Button href='/' color='inherit'><FaArrowAltCircleLeft size={30} /></Button>
                </div>
                
            </div>
            {/* <ToWriteBox /> */}
            
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="When we have nightmares it sucks, but the daydreams are here to help eliminate the nightmare monsters"
                textc=""
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image=""
                video="https://www.youtube.com/embed/UMhPGQZomJg" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Spy Hunting is being developed based on some retro games and has some iconic characters from the big screen"
                textc=""
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image=""
                video="https://www.youtube.com/embed/Ns2bvraTi_Y" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="To learn more about the game Mafia Escape, a small gameplay of our game, I love working on this development!!"
                textc=""
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image=""
                video="https://www.youtube.com/embed/ZcWOrRVWIHA" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Childhood Nightmare is a demo that mixes survival and Horror, choose your daydream and eliminate the nightmares"
                textc=""
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image={sschild} />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Mafia Escape is available on GooglePlay"
                textc="Escape and survive by escaping the Mafia"
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image={ssmafia} />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Ever thought about being a spy, this is the time"
                textc="Demo available for PC at Itchio"
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image={ssspy} />
            {/* {postList.map(post => (
                

            ))} */}


        </div>
    )
}

export default Feed