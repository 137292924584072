import React from 'react'
import { AppText } from '../../Constants'
import { Link } from 'react-router-dom'
import {FaBookReader} from 'react-icons/fa'

const TermsLayout = () => {
    return (
        <div className='w-full bg-gradient-to-b from-black via-black to-gray-900 text-white'>
            <div className='max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full'>
                <div className='pt-20'>
                    <p className='flex text-4xl font-bold border-b-4 border-gray-500'><FaBookReader size={30} className='mr-2'/> {AppText.termsuse}</p>
                </div>
                <div className='text-sm mt-6'>
                    <br></br>
                    <p className='text-lg font-bold'>{AppText.termsuse}</p>
                    <br></br>
                    <p className='mb-1'>Below are the rules applicable to the use of all Apps made available by <span className='font-bold'>{AppText.CaptainLobster}</span>, headquartered in the city of Guarulhos, hereinafter “<span className='font-bold'>{AppText.CaptainLobster}</span>”, on mobile devices with Android systems initially.</p>
                    <p className='mb-1'>When registering to use any and all applications, the User will automatically submit to the rules and conditions of these <span className='font-bold'>{AppText.termsuse}</span></p>
                    <br></br>
                    <p className='text-lg font-bold'>1. DEFINITIONS</p>
                    <br></br>
                    <p className='mb-1'>1.1. App or mobile application: is software designed to be installed on a mobile electronic device, such as a cell phone, a smartphone. This application can be installed on the device, if the device allows it and as long as it is downloaded by the user through an online store, such as Google Play or Apple Store, among others.</p>
                    <p className='mb-1'>1.2. Android: is an operating system for mobile devices, developed by the "Open Handset Alliance", led by Google.</p>
                    <p className='mb-1'>1.3. iOS: is a mobile operating system by Apple Inc originally developed for the iPhone.</p>
                    <p className='mb-1'>1.4. Push Technology: is a content distribution system on the Internet in which information leaves a server for a client, based on a series of parameters established by the client, also called “signature”. In the case of this Term of Use, it is content to be sent in the form of alerts, reminders or messages to the user who has any application installed.</p>
                    <p className='mb-1'>1.5. Users: Everyone, without restrictions, will be able to download and use any application developed by <span className='font-bold'>{AppText.CaptainLobster}</span>. Users will have access to features:</p>
                    <br></br>
                    <p className='mb-2'>• Entry screen;</p>
                    <p className='mb-2'>• Shop screen with items for purchase;</p>
                    <p className='mb-2'>• Game scene;</p>
                    <p className='mb-2'>• Updates when available;</p>
                    <p className='mb-2'>• Access to policies and terms in the application and on the page;</p>
                    <p className='mb-2'>• Access to data deletion request;</p>
                    <p className='mb-2'>• Support on our channels.</p>
                    <br></br>
                    <p className='text-lg font-bold'>2. DOWNLOADING APPLICATIONS</p>
                    <br></br>
                    <p className='mb-1'>2.1. For the user to be able to download the application on his mobile device, he must make sure that he has Android. There will be no other way to access <span className='font-bold'>{AppText.CaptainLobster}</span> applications other than through your smartphone.</p>
                    <p className='mb-1'>2.2. The “Term of Use and Privacy Policy" for users is present in the links and the user can review them at any time.</p>
                    <br></br>
                    <p className='text-lg font-bold'>3. THE FREE AND BILING APPLICATIONS</p>
                    <br></br>
                    <p className='mb-1'>3.1. There will be no charge for using and downloading some <span className='font-bold'>{AppText.CaptainLobster}</span> applications, nor for any updates to them, as long as they are free.</p>
                    <p className='mb-1'>3.2. for the user who wants to purchase the products contained in the game store, he must make the purchase directly through the application, respecting the privacy policies and terms of purchase of google that can be accessed by clicking on this link <Link to="https://policies.google.com/privacy" className='text-orange-500'>Google Play Services</Link>.</p>
                    <br></br>
                    <p className='text-lg font-bold'>4. REGISTRATION AND USE OF CAPTAIN LOBSTER STUDIO APPS</p>
                    <br></br>
                    <p className='mb-1'>4.1. At the moment, no registration is required to use the applications, but in future updates there may be changes in the way of accessing the application in order to improve the user experience.</p>
                    <p className='mb-1'>4.2. In the future, the user who registers his personal information will be responsible for correctly completing and maintaining said information. The "Registration" consists of mandatory data such as name, email and password to access the applications.</p>
                    <p className='mb-1'>4.3. A single registration per User will be allowed, and access, viewing and use of the Application must be made by the User on a personal and non-transferable basis.</p>
                    <p className='mb-1'>4.4. In the case of minors under 18 (eighteen) years of age or other persons who need legal representation, registration must be carried out with the assistance of parents or legal representatives.</p>
                    <p className='mb-1'>4.5. It is not <span className='font-bold'>{AppText.CaptainLobster}</span> responsibility if a User uses the registration of another user.</p>
                    <p className='mb-1'>4.6. Any and all actions performed or content published by the User during the use of the application will be their exclusive and full responsibility, and <span className='font-bold'>{AppText.CaptainLobster}</span> shall be exempt and indemnified from any claims, damages, losses and damages caused as a result of such actions or manifestations.</p>
                    <p className='mb-1'>4.7. The User authorizes <span className='font-bold'>{AppText.CaptainLobster}</span> or third parties indicated by it, to use, for an indefinite period, the information provided at the time of registration and during the use of the application, for statistical purposes and sending advertising material, newsletters, reports, etc.</p>
                    <p className='mb-1'>4.8. <span className='font-bold'>{AppText.CaptainLobster}</span> reserves the right to include, delete or change the contents and functionality of the application, as well as temporarily suspend or cancel it, at any time, regardless of prior notice to the User. Likewise, you can modify these Terms of Use, the latest version of which will always be available for consultation through the link or directly in the application.</p>
                    <p className='mb-1'>4.9. <span className='font-bold'>{AppText.CaptainLobster}</span> disclaims any and all liability for damages of any nature that may arise from access, interception, elimination, alteration, modification or manipulation, by unauthorized third parties, of user data while using the application.</p>
                    <p className='mb-1'>4.10. The information requested from the User at the time of registration will be used by <span className='font-bold'>{AppText.CaptainLobster}</span> only for the purposes set forth in these Terms of Use and under no circumstances will such information be transferred or shared with third parties, except by court order or competent authority.</p>
                    <br></br>
                    <p className='text-lg font-bold'>5. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</p>
                    <br></br>
                    <p className='mb-1'>5.1. <span className='font-bold'>{AppText.CaptainLobster}</span> is responsible for its services and management of the application in relation to users, however, the quality and quantity of products and services offered by our partners are the sole responsibility of the latter.</p>
                    <p className='mb-1'>5.2. The User is responsible for updating the Apps. If you do not do this and have a problem using it, <span className='font-bold'>{AppText.CaptainLobster}</span> will be exempt from liability for any loss or damage.</p>
                    <p className='mb-1'>5.3. <span className='font-bold'>{AppText.CaptainLobster}</span> does not represent or warrant that the Application will be free from loss, defects, attacks, viruses, interference, hacking activities or other security intrusion, and <span className='font-bold'>{AppText.CaptainLobster}</span> disclaims any liability in connection therewith to be the fault of the 3rd.</p>
                    <br></br>
                    <p className='text-lg font-bold'>6. INTELLECTUAL PROPERTY</p>
                    <br></br>
                    <p className='mb-1'>6.1. The User agrees that the services provided by <span className='font-bold'>{AppText.CaptainLobster}</span>, including, without limitation, the Application with user interface, integration with other services or applications, scripts and software used to implement it, contain exclusive information proprietary to <span className='font-bold'>{AppText.CaptainLobster}</span> and/ or its licensors and are protected by applicable intellectual property and other laws, including, without limitation, copyright.</p>
                    <p className='mb-1'>6.2. The User agrees that it will not use such proprietary information or materials in any way, except for use of the Services in accordance with these Terms of Use. No part of the Services may be reproduced in any form or by any means, except as expressly permitted by these terms.</p>
                    <p className='mb-1'>6.3. Without prejudice to any provision of these Terms, <span className='font-bold'>{AppText.CaptainLobster}</span> and its representatives reserve the right to change, suspend, remove or disable access to the application, content or other materials, at any time, without notice or charge. Under no circumstances will <span className='font-bold'>{AppText.CaptainLobster}</span> be held responsible for having made such changes. <span className='font-bold'>{AppText.CaptainLobster}</span> may further impose limits on the use of or access to certain functionalities or parts of the application service, in any case and without notice or liability.</p>
                    <p className='mb-1'>6.4. All copyrights related to applications and software developed by <span className='font-bold'>{AppText.CaptainLobster}</span> are owned by <span className='font-bold'>{AppText.CaptainLobster}</span> or its licensors, who reserve all rights provided by law and equity. The use of the software or any part of the services thereof, except for the use permitted in this term, is strictly prohibited and violates the intellectual property rights of third parties, and may subject you to civil and criminal penalties, including possible property damages for violation of Copyright.</p>
                    <br></br>
                    <p className='text-lg font-bold'>7. CANCELLATION</p>
                    <br></br>
                    <p className='mb-1'>7.1. The user may, at any time, uninstall any and all apps or software, without this generating the right to compensation or any other amount for either party.</p>
                    <p className='mb-1'>7.2. Without prejudice to other legal sanctions and those set forth herein, <span className='font-bold'>{AppText.CaptainLobster}</span> may, at its discretion, notify, suspend and/or cancel the User's registration, terminate this Term and/or Account, revoke the software license, prevent the access to the application or any part of it at any time, definitively or temporarily, in the following cases, exemplifying, but not limited to:</p>
                    <br></br>
                    <p className='mb-2 ml-4'>1.a Failure to comply with any provisions of this Term of Use;</p>
                    <p className='mb-2 ml-4'>2.b Verification of duplicate registration;</p>
                    <p className='mb-2 ml-4'>3.c Verification of a new registration made by a User whose registration was canceled and/or suspended;</p>
                    <p className='mb-2 ml-4'>4.d Finding of fraud or attempted fraud; and/or,</p>
                    <p className='mb-2 ml-4'>5.e Providing incorrect and/or untrue requested information or even refusing to provide any additional information requested by the Site.</p>
                    <br></br>
                    <p className='mb-1'>7.3. Termination, for whatever reason, of the relationship between <span className='font-bold'>{AppText.CaptainLobster}</span> and the User.</p>
                    <p className='mb-1'>7.4. <span className='font-bold'>{AppText.CaptainLobster}</span> reserves the right to change, suspend or discontinue the Apps developed by it, or any part or content, at any time, with or without prior notice, and <span className='font-bold'>{AppText.CaptainLobster}</span> will not be liable to the User or any third party in case exercise such rights.</p>
                    <br></br>
                    <p className='text-lg font-bold'>8. GENERAL PROVISIONS</p>
                    <br></br>
                    <p className='mb-1'>8.1. <span className='font-bold'>{AppText.CaptainLobster}</span> reserves the right, at any time, to modify these Terms of Use and its Privacy Policy and to impose new or additional terms or conditions on your use of the application. Such modifications and additional terms and conditions will be effective immediately and will be incorporated into this Term.</p>
                    <p className='mb-1'>8.2. If any term or condition of our Agreement is found to be invalid, illegal or unenforceable, the parties agree that such term or condition may be deleted and the remainder of the Agreement shall continue in effect indefinitely.</p>
                    <p className='mb-1'>8.3. <span className='font-bold'>{AppText.CaptainLobster}</span> may, without prior notice, block and cancel access to the application when it verifies that the User has committed any act or maintains conduct that (i) violates federal, state and/or municipal laws and regulations, (ii) contradicts the rules of these Terms of Use, or (iii) violates the principles of morality and good customs.</p>
                    <p className='mb-1'>8.4. This Term of Use and Privacy Policy will be governed and interpreted in accordance with the laws of Brazil. To resolve any doubts about this instrument, as well as any event related to the use of our services, the Central Forum, of the Judicial District of the Capital of the State of São Paulo is elected to resolve any issues arising from these Terms of Use.</p>
                    <br></br>
                    <p className='text-lg font-bold'>Contact us</p>
                    <br></br>
                    <p>If you have any questions or suggestions about my {AppText.termsuse}, do not hesitate to contact me at <a href='mailto:andreluizprado@captainlobsterstudio.com' className='text-orange-500'>andreluizprado@captainlobsterstudio.com</a></p>
                </div>
            </div>
        </div>
    )
}

export default TermsLayout