import React from 'react'
import TermsLayout from '../Components/polices/TermsLayout'
import NavbarPolices from '../Components/polices/NavbarPolices'
import Footer from '../Components/Footer'

function TermsOfUse() {
  return (
    <div>
      <NavbarPolices/>
      <TermsLayout/>
      <Footer/>
    </div>
  )
}

export default TermsOfUse