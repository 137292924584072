import React from 'react'
import './../../css/Diary.css'

const SidebarOptions = ({text, Icon}) => {

  return (
    <div className="sidebarOptions">
        <Icon />
        <h2 className='hidden md:block'>{text}</h2>
    </div>
  )
}

export default SidebarOptions