import React from 'react'
import { AppText } from '../../Constants'
import {MdDeleteOutline} from 'react-icons/md'

const DataLayout = () => {
    return (
        <div className='w-full h-screen bg-gradient-to-b from-black via-black to-gray-900 text-white'>
            <div className='max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full'>
                <div className='pb-8'>
                    <p className='flex text-4xl font-bold border-b-4 border-gray-500'><MdDeleteOutline size={40}/>{AppText.aboutdata}</p>
                </div>
                <div className='text-sm mt-6'>
                    <br></br>
                    <p className='text-lg font-bold'>{AppText.infoaboutdata}</p>
                    <br></br>
                    <p className='mb-1'>The General Law for the Protection of Personal Data (LGPD), Law n° 13.709/2018, was enacted to protect the fundamental rights of freedom and privacy and the free formation of the personality of each individual.</p>
                    <p className='mb-1'>The Law talks about the processing of personal data, arranged in physical or digital means, carried out by an individual or legal entity governed by public or private law, encompassing a wide range of operations that can occur in manual or digital means.</p>
                    <p className='mb-1'>To request the deletion of the data collected by our system, it is necessary to send an email to <a href='mailto:andreluizprado@captainlobsterstudio.com' className='text-orange-500'>andreluizprado@captainlobsterstudio.com</a>, with the following data:</p>
                    <br></br>
                    <p className='mb-2'>• Subject – Deletion of personal data</p>
                    <p className='mb-2'>• Registration email;</p>
                    <p className='mb-2'>• Account owner name;</p>
                    <p className='mb-2'>• Photo of the original document, which may be RG, CNH or equivalent to prove ownership.</p>
                    <br></br>
                    <p className='text-lg font-bold'>Contact us</p>
                    <br></br>
                    <p>If you have any questions or suggestions about my {AppText.termsuse}, do not hesitate to contact me at <a href='mailto:andreluizprado@captainlobsterstudio.com' className='text-orange-500'>andreluizprado@captainlobsterstudio.com</a></p>
                </div>
            </div>
        </div>
    )
}

export default DataLayout