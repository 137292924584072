import React from 'react'
import { Link } from 'react-router-dom'
import { AppText } from '../Constants'

const Footer = () => {
  return (
    <div className='text-white bg-gray-900 w-full h-120'>
      <div className='max-w-screen-xl p-4 mx-auto flex flex-col justify-center '>
        <div className='pb-2 mx-auto'>
          <p>{AppText.copywriteText}</p>
        </div>
        <div className='text-sm mt-6 mx-auto'>
          <ul className='flex flex-row'>
            <li className='px-4 cursor-pointer capitalize font-medium
           text-gray-400 hover:scale-105 hover:text-orange-600 
           duration-200 ease-in-out'><Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li className='px-4 cursor-pointer capitalize font-medium
           text-gray-400 hover:scale-105 hover:text-orange-600 
           duration-200 ease-in-out'><Link to="/terms-of-use">Terms of use</Link>
            </li>
            <li className='px-4 cursor-pointer capitalize font-medium
           text-gray-400 hover:scale-105 hover:text-orange-600 
           duration-200 ease-in-out'><Link to="/about-data-deletion">About data deletion</Link>
            </li>
          </ul>
        </div>

      </div>
    </div>
  )
}

export default Footer