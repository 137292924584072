import React from 'react'
import { AppText } from '../Constants'
import {MdOutlineKeyboardArrowRight} from 'react-icons/md'
import { lobsterlogosite } from '../assets'
import { Link } from 'react-scroll'

const Home = () => {
  return (
    <div name="home" className='h-screen w-full bg-gradient-to-b from-black via-black to-gray-900'>
      <div className='max-w-screen-xl mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
        <div className='flex flex-col justify-center h-full'>
          <h1 className='text-white text-4xl font-bold md:text-5xl'>{AppText.hello}</h1>
          <h1 className='text-white text-4xl font-bold md:text-5xl'>{AppText.Iam}<span className='text-orange-600 text-4xl font-bold md:text-5xl'> {AppText.CaptainLobster}</span></h1>
          <p className='text-gray-500 py-4 max-w-md'>{AppText.introDescription}</p>
          <div>
            <Link to='about' smooth duration={500} className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md
             bg-gradient-to-r from-orange-400 to-orange-500 cursor-pointer'>Read More
              <span className='group-hover:rotate-90 duration-300'><MdOutlineKeyboardArrowRight size={25} className='ml-1'/></span>
            </Link>
          </div>
        </div>
        <div>
          <img src={lobsterlogosite} alt='captain logo' className='mx-auto w-50 mt-[-40px] md:w-40 md:mt-0'/>
        </div>
      </div>
    </div>
  )
}

export default Home