import React from 'react'
import { BsArrowLeftCircleFill } from 'react-icons/bs'
import { textlobster } from '../../assets'
import { Link } from 'react-router-dom'

const NavbarPolices = () => {
    return (
        <div className='flex justify-between items-center w-full h-20 text-white fixed bg-black'>
            <div>
                <img src={textlobster} className='h-10 px-6' alt='logo lobster'></img>
            </div>
            <ul className='flex'>
            <li className='px-4 cursor-pointer capitalize font-medium
           text-gray-400 hover:scale-105 hover:text-orange-600 
           duration-200 ease-in-out'><Link to="/" smooth duration={500}><BsArrowLeftCircleFill size={30}/></Link></li>
            </ul>
        </div>
    )
}

export default NavbarPolices