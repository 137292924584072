import React from 'react'
import './../../css/Diary.css'
import { Avatar } from '@mui/material'
import VerifiedIcon from '@mui/icons-material/Verified'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import RepeatIcon from '@mui/icons-material/Repeat';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import UploadIcon from '@mui/icons-material/Upload';
import ReactPlayer from 'react-player/lazy';

function Post({ displayName, username, verified, timestamp, text, textc, image, avatar, video }) {
    return (
        <div className='post'>
            <div className='post_avatar'>
                <Avatar src={avatar}/>
            </div>
            <div className='post_body'>
                <div className='post_header'>
                    <div className='post_headerText'>
                        <h3>{displayName}{" "}
                            <span className='post_headerSpecial'>
                            {verified && <VerifiedIcon className='post_badge'/>}
                            <span>@{username}</span>
                        </span>
                        </h3>
                    </div>
                    <div className='post_headerDescription'>
                        <p>{text}</p>
                        {textc && <p>{textc}</p>}
                    </div>
                </div>
                {image && <img src={image} alt='imagepost' className='post_image'></img>}
                {video && <ReactPlayer controls url={video} width={450}/>}
                <div className='post_footer'>
                    <ChatBubbleOutlineIcon fontSize='small'/>
                    <RepeatIcon fontSize='small'/>
                    <FavoriteBorderIcon fontSize='small'/>
                    <UploadIcon fontSize='small'/>
                </div>
            </div>
        </div>
    )
}

export default Post