import NavBar from './Components/NavBar';
import Home from './Components/Home';
import SocialLink from './Components/SocialLink'
import Games from './Components/Games';
import About from './Components/About';
import Software from './Components/Softwares';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
function App() {
  return (
    <div>
      {/* Nav Bar */}
      <NavBar />
      {/* Intro */}
      <Home />
      <SocialLink />
      {/* Games */}
      <Games />
      {/* About */}
      <About />
      {/* Software */}
      <Software />
      {/* Contact */}
      <Contact/>
      {/* Footer */}
      <Footer/>
    </div>
  );
}

export default App;
