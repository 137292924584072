import React, {useState} from 'react'
import { lobsterlogosite } from '../../assets'
import './../../css/Diary.css'
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SidebarOptions from './SidebarOptions';
import { Button } from '@mui/material';
import { useHookstate } from '@hookstate/core';
import store from '../../store';
import { FaHome } from 'react-icons/fa';

const Sidebar = () => {

  const tabselected = useHookstate(store)

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) =>{
    setToggleState(index);
  }

  return (
    <div className='hidden md:min-w-[250px] md:ml-[20px] md:block ml-[-8px] mr-0 flex-[0.3]' tabselected={tabselected.set(toggleState)}>
      <img src={lobsterlogosite} alt='Lobster Logo' className='sidebar_captainicon h-10'></img>
      <div onClick={() => toggleTab(1)} className={toggleState === 1 ? "sidebarOptions--active" : ""}>
        <SidebarOptions Icon={AutoStoriesIcon} text="Captain's Diary"/>
      </div>
      <div onClick={() => toggleTab(2)} className={toggleState === 2 ? "sidebarOptions--active" : ""}>
        <SidebarOptions Icon={NewReleasesIcon} text="News"/>
      </div>
      <div onClick={() => toggleTab(3)} className={toggleState === 3 ? "sidebarOptions--active" : ""}>
        <SidebarOptions Icon={Filter1Icon} text="Escape"/>
      </div>
      <div onClick={() => toggleTab(4)} className={toggleState === 4 ? "sidebarOptions--active" : ""}>
        <SidebarOptions Icon={Filter2Icon} text="Nightmare"/>
      </div>
      <div onClick={() => toggleTab(5)} className={toggleState === 5 ? "sidebarOptions--active" : ""}>
        <SidebarOptions Icon={Filter3Icon} text="Spy"/>
      </div>
      <div onClick={() => toggleTab(6)} className={toggleState === 6 ? "sidebarOptions--active" : ""}>
        <SidebarOptions Icon={PersonOutlineIcon} text="Profile"/>
      </div>
      <div className='hidden md:block'>
        <Button href='/' className='sidebar_button' variant='outlined'>Home</Button>
      </div>
      <div className='md:hidden'>
        <Button href='/' className='sidebar_button_md' variant='outlined'><FaHome size={30}/></Button>
      </div>
      
    </div>
  )
}

export default Sidebar