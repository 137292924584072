import React from 'react'
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaWhatsappSquare, FaTwitter } from 'react-icons/fa'
import { TbMailFilled } from 'react-icons/tb'


const SocialLink = () => {

return (
    <div className='hidden lg:flex flex-col top-[35%] left-0 fixed'>
        <ul>
            <li className='flex justify-between items-center w-40 h-14 
             px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md hover:opacity-100 hover:bg-indigo-500 duration-300 bg-gray-800 opacity-50 rounded-tr-md'>
                <a href='https://www.facebook.com/captainlobsterstudio' target='blank' className='flex justify-between items-center w-full
                 text-white'>
                    <>
                        Facebook<FaFacebook size={25} />
                    </>
                </a>
            </li>
            <li className='flex justify-between items-center w-40 h-14 
             px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md hover:opacity-100 hover:bg-fuchsia-500 duration-300 bg-gray-800 opacity-50'>
                <a href='https://www.instagram.com/captainlobsterstudio/' target='blank' className='flex justify-between items-center w-full
                 text-white'>
                    <>
                        Instagram<FaInstagramSquare size={25} />
                    </>
                </a>
            </li>
            <li className='flex justify-between items-center w-40 h-14 
             px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md hover:opacity-100 hover:bg-sky-500 duration-300 bg-gray-800 opacity-50'>
                <a href='https://twitter.com/studio_lobster' target='blank' className='flex justify-between items-center w-full
                 text-white'>
                    <>
                        Twitter<FaTwitter size={25} />
                    </>
                </a>
            </li>
            <li className='flex justify-between items-center w-40 h-14 
             px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md hover:opacity-100 hover:bg-blue-800 duration-300 bg-gray-800 opacity-50'>
                <a href='https://www.linkedin.com/in/andre-luiz-prado-de-oliveira-8a772566/' target='blank' className='flex justify-between items-center w-full
                 text-white'>
                    <>
                        Linkedin<FaLinkedin size={25} />
                    </>
                </a>
            </li>
            <li className='flex justify-between items-center w-40 h-14 
             px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md hover:opacity-100 hover:bg-green-500 duration-300 bg-gray-800 opacity-50'>
                <a href='https://call.whatsapp.com/voice/jxMGvZzAYekibwlBJX5SCT' target='blank' className='flex justify-between items-center w-full
                 text-white'>
                    <>
                        Whatsapp<FaWhatsappSquare size={25} />
                    </>
                </a>
            </li>
            <li className='flex justify-between items-center w-40 h-14 
             px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md hover:opacity-100 hover:bg-orange-500 duration-300 bg-gray-800 opacity-50 rounded-br-md'>
                <a href='mailto:andreluizprado@captainlobsterstudio.com' className='flex justify-between items-center w-full
                 text-white'>
                    <>
                        E-mail<TbMailFilled size={25} />
                    </>
                </a>
            </li>
        </ul>
    </div>
)
}

export default SocialLink