import React from 'react'
import './../css/Diary.css'
import Sidebar from '../Components/diary/Sidebar'
import Feed from '../Components/diary/Feed'
import Widgets from '../Components/diary/Widgets'
import { useHookstate } from '@hookstate/core'
import store from '../store'
import News from '../Components/diary/News'
import Escape from '../Components/diary/Escape'
import Nightmare from '../Components/diary/Nightmare'
import Spy from '../Components/diary/Spy'
import Profile from '../Components/diary/Profile'

const Diary = () => {

  const tabselected = useHookstate(store)

  return (
    <div className='diary_layout'>
      {/** Sidebar Section */}
        <Sidebar />

      {/** Feed Section */}
      {tabselected.value === 1 ? <Feed /> : ''}
      {tabselected.value === 2 ? <News /> : ''}
      {tabselected.value === 3 ? <Escape /> : ''}
      {tabselected.value === 4 ? <Nightmare /> : ''}
      {tabselected.value === 5 ? <Spy /> : ''}
      {tabselected.value === 6 ? <Profile /> : ''}
      {/** Widgets Section */}
      <div className='hidden lg:block'>
        <Widgets />
      </div>

    </div>
  )
}

export default Diary