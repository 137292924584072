import React from 'react'
import './../../css/Diary.css'
import SearchIcon from '@mui/icons-material/Search';
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterTweetEmbed
} from 'react-twitter-embed'

const Widgets = () => {
  return (
    <div className='widgets'>
      <div className='widgets_input'>
        <SearchIcon className='widgets_searchIcon' />
        <input placeholder='Search in the diary' type='text'></input>
      </div>
      <div className='widgets_widgetsContainer'>
        <h2>What's happening</h2>
        <TwitterTweetEmbed tweetId={"1679250848298283011"} />
        <TwitterTimelineEmbed sourceType="profile" screenName="studio_lobster" options={{ height: 400 }} />
        <TwitterShareButton url={"https://twitter.com/studio_lobster"}
          options={{ text: '#captainlobsterstudio is awesome', via: 'captainlobster' }} />
      </div>
    </div>
  )
}

export default Widgets