import React from 'react'
import './../../css/Diary.css'
import Post from './Post'
import { sschild, perfil, ssnight01, ssnight02, ssnight03, ssnight04, ssnight05, ssnight06, textchild } from '../../assets'
import { FaGooglePlay } from 'react-icons/fa'

let urlChild = 'https://play.google.com/store/apps/details?id=com.captainlobster.childhoodnightmare'
const Nightmare = () => {
    return (
        <div className='nightmare'>
            <div className='nightmare_header'>
                <h2>Childhood Nightmare</h2>
            </div>
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="A dream that becomes a nightmare, that's the premise of Childhood Nightmare, childhood nightmares that come true."
                textc=""
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="In this nightmare you become a daydream, and you will have to eliminate the children of the nightmare."
                textc="Childhood Nightmare is based on shooter and horror games, your biggest challenge is to survive long enough to eliminate the one that imprisons souls of those who failed there."
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="There are 4 initial daydreams and in future updates they gained some more friends and new powers to face the story mode and hardcore survival"
                textc="Fire Daydream"
                avatar={perfil}
                image={ssnight04}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Light Daydream"
                textc=""
                avatar={perfil}
                image={ssnight02}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Ice Daydream"
                textc=""
                avatar={perfil}
                image={ssnight03}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Nature Daydream"
                textc=""
                avatar={perfil}
                image={ssnight05}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Be prepared, nightmares are random and you never know where you'll end up."
                textc=""
                avatar={perfil}
                image={ssnight01}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Looks like I chipped!!!"
                textc=""
                avatar={perfil}
                image={ssnight06}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="When we have nightmares it sucks, but the daydreams are here"
                textc="to help eliminate the nightmare monsters"
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image=""
                video="https://www.youtube.com/embed/UMhPGQZomJg" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Childhood Nightmare is a demo that mixes survival and"
                textc="Horror, choose your daydream and eliminate the nightmares"
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image={sschild} />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Childhood Nightmare is available on GooglePlay"
                textc={<FaGooglePlay onClick={()=> window.open(urlChild, '_blank')} size={30} color='white' className='cursor-pointer'/>}
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image={textchild} />
        </div>
    )
}

export default Nightmare