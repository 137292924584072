import React from 'react'
import { AppText } from '../../Constants'
import { Link } from 'react-router-dom'
import {MdPolicy} from 'react-icons/md'

function PolicyLayout() {
    return (
        <div className='w-full bg-gradient-to-b from-black via-black to-gray-900 text-white'>
            <div className='max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full'>
                <div className='pt-20'>
                    <p className='flex text-4xl font-bold border-b-4 border-gray-500'><MdPolicy size={40}/>{AppText.policyprivacy}</p>
                </div>
                <div className='text-sm mt-6'>
                    <br></br>
                    <p className='text-lg font-bold'>{AppText.policyprivacy}</p>
                    <br></br>
                    <p className='mb-1'><span className='font-bold'>{AppText.CaptainLobster}</span> built the game and app as a Freemium app. This SERVICE is provided by <span className='font-bold'>{AppText.CaptainLobster}</span> at no cost and is intended for use as is.</p>
                    <p className='mb-1'>This page is used to inform visitors about my policies for collecting, using and disclosing Personal Information if anyone decides to use my Service.</p>
                    <p className='mb-1'>If you choose to use our Services, you consent to the collection and use of information in connection with this policy. The Personal Information I collect is used to provide and improve the Service. I will not use or share your information with anyone except as described in this Privacy Policy.</p>
                    <p className='mb-1'>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible on game and apps unless otherwise defined in this Privacy Policy.</p>
                    <br></br>
                    <p className='mb-1'>Collection and use of information or a better experience, when using our Service, I may require you to provide us with certain personally identifiable information, including, but not limited to, email address, cell phone, internet protocol, credit/debit card, e-commerce application, images. </p>
                    <p className='mb-1'>information I request will be retained on your device and will not be collected by me in any way.</p>
                    <p className='mb-1'>The app uses third-party services that may collect information used to identify you.</p>
                    <p className='mb-1'>Link to the privacy policy of third party service providers used by the app:</p>
                    <br></br>
                    <p className='mb-2 hover:text-orange-500'><Link to="https://policies.google.com/privacy" target='blank'>·  Google Play Services</Link></p>
                    <p className='mb-2 hover:text-orange-500'><Link to="https://support.google.com/admob/answer/6128543?hl=en" target='blank'>·  AdMob</Link></p>
                    <p className='mb-2 hover:text-orange-500'><Link to="https://marketingplatform.google.com/about/analytics/terms/us/" target='blank'>·  Google Analytics for Firebase</Link></p>
                    <p className='mb-2 hover:text-orange-500'><Link to="https://firebase.google.com/support/privacy" target='blank'>·  Firebase Crashlytics</Link></p>
                    <p className='mb-2 hover:text-orange-500'><Link to="https://www.facebook.com/about/privacy/update/printable" target='blank'>·  Facebook</Link></p>
                    <p className='mb-2 hover:text-orange-500'><Link to="https://unity.com/legal/privacy-policy" target='blank'>·  Unity</Link></p>
                    <br></br>
                    <p className='mb-1'>I want to inform you that whenever you use my Service, in case of an error in the application, I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device's Internet Protocol ("IP") address, device name, operating system version, application configuration when using my Service, the time and date of your use of the Service. Service and other statistics.</p>
                    <br></br>
                    <p className='text-lg font-bold'>Cookies</p>
                    <br></br>
                    <p className='mb-1'>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. They are sent to your browser from the websites you visit and are stored on your device's internal memory.</p>
                    <p className='mb-1'>This Service does not explicitly use these “cookies”. However, the app may use third-party code and libraries that use “cookies” to collect information and improve its services. You have the option to accept or decline these cookies and to know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some parts of this Service.</p>
                    <br></br>
                    <p className='text-lg font-bold'>Service provider</p>
                    <br></br>
                    <p className='mb-2'>I may employ third party companies and individuals for the following reasons:</p>
                    <p className='mb-2'>· To facilitate our Service;</p>
                    <p className='mb-2'>· To provide the Service on our behalf;</p>
                    <p className='mb-2'>· To perform services related to the Service or;</p>
                    <p className='mb-2'>· To help us analyze how our Service is used.</p>
                    <br></br>
                    <p className='mb-1'>I want to inform users of this Service that these third parties have access to your Personal Information. The motive is to carry out the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
                    <br></br>
                    <p className='text-lg font-bold'>Safety</p>
                    <br></br>
                    <p className='mb-1'>I value your trust in providing us with your personal information, therefore we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage, is 100% secure and reliable, and I cannot guarantee its absolute security.</p>
                    <br></br>
                    <p className='text-lg font-bold'>Links to other websites</p>
                    <br></br>
                    <p className='mb-1'>This Service may contain links to other websites. If you click on a third party link, you will be directed to that website. Please note that these external websites are not operated by me.</p>
                    <p className='mb-1'>Therefore, I strongly advise you to review the Privacy Policy of these sites. I have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.</p>
                    <br></br>
                    <p className='text-lg font-bold'>Children's privacy</p>
                    <br></br>
                    <p className='mb-1'>These Services are not intended for persons under the age of 13. I do not knowingly collect personally identifiable information from children under the age of 13. In the event that I discover that a child under the age of 13 has provided me with personal information, I immediately delete it from our servers. </p>
                    <p className='mb-1'>If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I can take appropriate action.</p>
                    <br></br>
                    <p className='text-lg font-bold'>Changes to this Privacy Policy</p>
                    <br></br>
                    <p className='mb-1'>I may update our Privacy Policy from time to time. Therefore, we recommend that you periodically review this page for any changes. I will notify you of any changes by posting the new Privacy Policy on this page.</p>
                    <p className='text-lg font-bold'>This policy is effective as of 2023-02-06</p>
                    <br></br>
                    <p className='text-lg font-bold'>Contact us</p>
                    <br></br>
                    <p>If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at <a href='mailto:andreluizprado@captainlobsterstudio.com' className='text-orange-500'>andreluizprado@captainlobsterstudio.com</a></p>
                </div>
            </div>
        </div>
    )
}

export default PolicyLayout