import React from 'react'
import { AppText } from '../Constants'
import { FaUnity, FaLaravel, FaVuejs, FaHtml5, FaPython } from 'react-icons/fa'
import { SiBlender, SiGimp, SiInkscape, SiVuetify, SiCsharp } from 'react-icons/si'

const Software = () => {
  return (
    <div name="expertise" className='w-full bg-gradient-to-b from-gray-900 via-gray-900 to-black text-white md:h-screen'>
      <div className='max-w-screen-xl mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div>
          <p className='text-4xl font-bold border-b-4 border-gray-500'>{AppText.Expertises}</p>
          <p className='text-xl mt-6 pb-6'>{AppText.myExpertises}</p>
        </div>
        <div className='w-full grid sm:grid-cols-2 md:grid-cols-4 text-center py-8 gap-8 px-12 sm:px-0'>
          <div className='group shadow-md shadow-gray-200 py-2 rounded-lg'>
            <FaUnity size={100} className='mx-auto duration-300 hover:scale-105'/>
            <p className='mt-4'>Unity</p>
          </div>
          <div className='group shadow-md shadow-orange-600 py-2 rounded-lg'>
            <SiBlender size={100} className='mx-auto duration-300 hover:scale-105'/>
            <p className='mt-4'>Blender</p>
          </div>
          <div className='group shadow-md shadow-gray-500 py-2 rounded-lg'>
            <SiInkscape size={100} className='mx-auto duration-300 hover:scale-105'/>
            <p className='mt-4'>Inkscape</p>
          </div>
          <div className='group shadow-md shadow-gray-400 py-2 rounded-lg'>
            <SiGimp size={100} className='mx-auto duration-300 hover:scale-105'/>
            <p className='mt-4'>Gimp</p>
          </div>
          <div className='group shadow-md shadow-green-600 py-2 rounded-lg'>
            <SiCsharp size={100} className='mx-auto duration-300 hover:scale-105'/>
            <p className='mt-4'>C#</p>
          </div>
          <div className='group shadow-md shadow-red-600 py-2 rounded-lg'>
            <FaLaravel size={100} className='mx-auto duration-300 hover:scale-105'/>
            <p className='mt-4'>Laravel</p>
          </div>
          <div className='group shadow-md shadow-blue-600 py-2 rounded-lg'>
            <FaPython size={100} className='mx-auto duration-300 hover:scale-105'/>
            <p className='mt-4'>Python</p>
          </div>
          <div className='group shadow-md shadow-emerald-600 py-2 rounded-lg'>
            <FaVuejs size={100} className='mx-auto duration-300 hover:scale-105'/>
            <p className='mt-4'>VueJs</p>
          </div>
          <div className='group shadow-md shadow-orange-600 py-2 rounded-lg'>
            <FaHtml5 size={100} className='mx-auto duration-300 hover:scale-105'/>
            <p className='mt-4'>HTML</p>
          </div>
          <div className='group shadow-md shadow-cyan-500 py-2 rounded-lg'>
            <SiVuetify size={100} className='mx-auto duration-300 hover:scale-105'/>
            <p className='mt-4'>Vuetify</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Software