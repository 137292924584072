import React from 'react'
import './../../css/Diary.css'
import Post from './Post'
import { perfil, cardCorvo, cardPig, cardRino, cardTiger, cardBear, textmafia } from '../../assets'
import { FaGooglePlay } from 'react-icons/fa'

let urlMafia = 'https://play.google.com/store/apps/details?id=com.captainlobster.mafiaescape';

const Escape = () => {
    return (
        <div className='escape'>
            <div className='escape_header'>
                <h2>Mafia Escape</h2>
            </div>
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Mafia Escape was conceived mixing a bit of the concepts of infinite running and shooter style games, where you have to escape and destroy obstacles."
                textc=""
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Behind this mix, characters were created based on animals from each region."
                textc=""
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Corvo, codenamed Corvus, a mafia sniper, but in one of his jobs he failed miserably, having to flee from the Yarcorvus family"
                textc=""
                avatar={perfil}
                image={cardCorvo}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Pig codenamed Domuzeti is looking for revenge against those who betrayed him and in this quest he ended up meeting rivals of his Triazeti family"
                textc=""
                avatar={perfil}
                image={cardPig}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Renoster, a hardcore rhino paratrooper, but he's been pissing off some families like the Rester family and now he really needs to hide."
                textc=""
                avatar={perfil}
                image={cardRino}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Tiger codenamed Typi is a mercenary hired by the mainland's mafia families, and right now he's looking for someone."
                textc=""
                avatar={perfil}
                image={cardTiger}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Bear Medved is undercover looking for a confession, but for that he will have to chase the fugitive a lot."
                textc=""
                avatar={perfil}
                image={cardBear}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="To learn more about the game Mafia Escape, a small gameplay of our game, I love working on this development!!"
                textc=""
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image=""
                video="https://www.youtube.com/embed/ZcWOrRVWIHA" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Mafia Escape is available on GooglePlay"
                textc={<FaGooglePlay onClick={()=> window.open(urlMafia, '_blank')} size={30} color='white' className='cursor-pointer'/>}
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image={textmafia} />
        </div>
    )
}

export default Escape