import React from 'react'
import './../../css/Diary.css'
import { perfil, logolobster } from '../../assets'
import Post from './Post'
import { FaGooglePlay } from 'react-icons/fa'

let urlCaptainGoogle = 'https://play.google.com/store/apps/dev?id=8151160736740595910'

const Profile = () => {
    return (
        <div className='profile'>
            <div className='profile_header'>
                <h2>Profile</h2>
            </div>
            <Post displayName="AndreLuizPrado"
                username="studio_lobster"
                verified={true}
                text="My name is André Luiz and I am the founder of Captain Lobster Studio, a rather funny name for a game developer, but that was the intention."
                textc=""
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="AndreLuizPrado"
                username="studio_lobster"
                verified={true}
                text="I'm a web and game developer, I work today but in the web area developing websites and learning new technologies every day."
                textc=""
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="AndreLuizPrado"
                username="studio_lobster"
                verified={true}
                text="But my passion for game development has always been very strong and I decided to venture into it. I'm still starting, but I believe that all the effort will be worth it in the future, very close I hope."
                textc=""
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Hey, don't forget to talk about me, after all I'm the one who will bring the fun to everyone..."
                textc=""
                avatar={logolobster}
                image=""
                video="" />
            <Post displayName="AndreLuizPrado"
                username="studio_lobster"
                verified={true}
                text="Of course I don't forget, Captain Lobster Studio was created to bring fun and nostalgic games, taking 2d games and giving them a new look and concepts."
                textc=""
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="AndreLuizPrado"
                username="studio_lobster"
                verified={true}
                text="Of course it is a very large market and I believe there is room for everyone. I'm going to take advantage of this space to publicize all the production news as well as show a little of the work done."
                textc=""
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="That's it, never give up, enjoy it guys and subscribe to our channels to give that strength and download our games, just click on the button there and I'll send you there."
                textc={<FaGooglePlay onClick={()=> window.open(urlCaptainGoogle, '_blank')} size={30} color='white' className='cursor-pointer'/>}
                avatar={logolobster}
                image=""
                video="" />
        </div>
    )
}

export default Profile