import React, { useState } from 'react'
import { FaBars, FaArrowAltCircleUp } from 'react-icons/fa'
import { textlobster } from '../assets'
import { Link} from 'react-scroll'

const NavBar = () => {

  const links = [
    {
      id: 1,
      link: 'home'
    },
    {
      id: 2,
      link: 'games'
    },
    {
      id: 3,
      link: 'about'
    },
    {
      id: 4,
      link: 'expertise'
    },
    {
      id: 5,
      link: 'contact'
    },
  ]
  const [toogle, setToogle] = useState(false);
  return (
    <div className='flex justify-between items-center w-full h-20 text-white fixed bg-black'>
      <div>
        <img src={textlobster} className='h-10 px-6' alt='logo lobster'></img>
      </div>

      <ul className='hidden md:flex'>
        {links.map(({id, link}) => (
          <li key={id} className='px-4 cursor-pointer capitalize font-medium
           text-gray-400 hover:scale-105 hover:text-orange-600 
           duration-200 ease-in-out'><Link to={link} smooth duration={500}>{link}</Link></li>
        ))}
      </ul>

      <div onClick={() => setToogle(!toogle)} className='cursor-pointer pr-4 z-10 text-gray-400 md:hidden'>
        {toogle ? <FaArrowAltCircleUp size={30}/> : <FaBars size={30} />}
      </div>

      {toogle && (
        <ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-900 text-gray-500'>
      {links.map(({id, link}) => (
          <li key={id} className='px-4 cursor-pointer capitalize font-semi-bold py-6 text-4xl text-gray-400 hover:scale-105 hover:text-orange-600 
          duration-200 ease-in-out'><Link onClick={() => setToogle(!toogle)} to={link} smooth duration={500}>{link}</Link></li>
        ))}
      </ul>
      )}
      
    </div>
  )
}

export default NavBar