import React from 'react'
import NavbarPolices from '../Components/polices/NavbarPolices'
import DataLayout from '../Components/polices/DataLayout'
import Footer from '../Components/Footer'

function AboutDataDeletion() {
  return (
    <div>
      <NavbarPolices/>
      <DataLayout/>
      <Footer/>
    </div>
  )
}

export default AboutDataDeletion