import React from 'react'
import { AppText } from '../Constants'
import { Link } from 'react-router-dom'
import { BiSolidBook} from 'react-icons/bi'

const About = () => {
  return (
    <div name="about" className='w-full h-screen bg-gradient-to-b from-black via-black to-gray-900 text-white'>
      <div className='max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
        <p className='text-4xl font-bold border-b-4 border-gray-500'>About</p>
        </div>
        <div className='text-xl mt-6'>
          <p>{AppText.aboutDescription}
          <span className='text-orange-600 text-2xl font-bold'> {AppText.CaptainLobster} </span>
           {AppText.aboutDescription1}</p>
          <br />
          <p>{AppText.aboutDescription2}</p>
          <Link to="/diary" className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md
             bg-gradient-to-r from-orange-400 to-orange-500 cursor-pointer'>Diary
              <span className='group-hover:scale-110 duration-300'><BiSolidBook size={25} className='ml-1'/></span></Link>
        </div>
      </div>
    </div>
  )
}

export default About