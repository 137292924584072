import React from 'react'
import './../../css/Diary.css'
import Post from './Post'

const News = () => {
    return (
        <div className='news'>
            <div className='news_header'>
                <h2>News</h2>
            </div>
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Time lapse of work on the Spy Hunting game"
                textc=""
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image=""
                video="https://www.youtube.com/embed/VSk1ZUlkGXY" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Time lapse of work on the Mafia Escape game"
                textc=""
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image=""
                video="https://www.youtube.com/embed/iEXl7YMRQSk" />
        </div>
    )
}

export default News