
export const AppText={
    hello: 'Hello,',
    Iam: 'I am',
    CaptainLobster: 'Captain Lobster Studio',
    IndepentStudio: 'an independet studio',
    introDescription: 'I am an independent game developer, focused on developing varied games for mobile and pc.',
    Welcome: 'Welcome,',
    My: 'My',
    Games: 'Games',
    gamesDescription: 'All games produced by me are inspired by old games, with a touch of newness. My focus is to develop a differentiated experience by putting a little of my passion for games.',
    gamePurpose: 'Below you can see some demos that have already been released.',
    aboutDescription: 'My name is André Luiz and I am the founder and developer of',
    aboutDescription1: 'with the intention of creating games with some plus, today I work in the development of games for mobile and pc, focusing on casual and hybrid games.',
    aboutDescription2: 'I worked with web development for some companies, I already taught game development classes in some courses and today, in addition to continuing web development, I decided ' +
    'to open this niche of games, which is very vast.',
    Expertises: 'Expertises',
    myExpertises: 'Software we use in development.',
    Contact: 'Contact Us',
    Email: 'email',
    Phone: 'phone number',
    copywriteText: '© by Captain Lobster Studio.',
    located: 'located in Brazil',
    submitform: 'Submit your form to get in touch',
    policyprivacy: 'Privacy Policy',
    termsuse: 'Terms of Use',
    aboutdata: 'About data deletion',
    infoaboutdata: 'Information about data deletion'
}

// export const gamesSection=[
//     {id: 1,
//      title: 'Mafia Escape',
//      image: '../assets/SSMafia.jpg',
//      desc: 'Inspired by endless running games, Mafia Escape brings different challenges, such as scenario destruction, setting with effects, among other things.',
//      link: 'https://play.google.com/store/apps/details?id=com.captainlobster.mafiaescape',
//     },
//     {id: 2,
//      title: 'Childhood Nightmare',
//      image: '',
//      desc: 'Speaking of survival, Childhood Nightmare enters the world of nightmares, where daydreaming will fight nightmares at dawn and be lucky to eliminate them all before waking up.',
//      link: 'https://play.google.com/store/apps/details?id=com.captainlobster.childhoodnightmare',
//     },
//     {id: 3,
//      title: 'Spy Hunting',
//      image: '',
//      desc: 'Elevator Action was the forerunner of the idea of ​​Spy Hunting, joining this plus the idea of ​​a world dominated by fake news, the game was created and already has a demo that will soon receive updates, such as the multiplayer mode and some more maps and characters.',
//      link: 'https://captain-lobster.itch.io/spy-hunting',
//     },
// ]