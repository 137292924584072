import React from 'react'
import './../../css/Diary.css'
import Post from './Post'
import { ssspy, spy01, spy02, spy03, spy05, spy06, spy07, chamadaspy, perfil } from '../../assets'
import {FaWindows} from 'react-icons/fa'

let urlSpy = 'https://captain-lobster.itch.io/spy-hunting'

const Spy = () => {
    return (
        <div className='spy'>
            <div className='spy_header'>
                <h2>Spy Hunting</h2>
            </div>
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Many companies found themselves in the midst of chaos in the AI era, a lot of fakenews brought chaos and now?"
                textc=""
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Spy Hunting is a shooter puzzle game, inspired by classic games, the intention is to become a spy and solve problems to escape."
                textc=""
                avatar={perfil}
                image=""
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Choose your iconic character and prove that you are the best spy among them all."
                textc="Agent XXX"
                avatar={perfil}
                image={spy01}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Agent XXX"
                textc=""
                avatar={perfil}
                image={spy02}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Agent XXX"
                textc=""
                avatar={perfil}
                image={spy03}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Agent XXX"
                textc=""
                avatar={perfil}
                image={spy05}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Agent XXX"
                textc=""
                avatar={perfil}
                image={spy06}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Agent XXX"
                textc=""
                avatar={perfil}
                image={spy07}
                video="" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Spy Hunting is being developed based on some retro games"
                textc="and has some iconic characters from the big screen"
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image=""
                video="https://www.youtube.com/embed/Ns2bvraTi_Y" />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Ever thought about being a spy, this is the time"
                textc="Demo available for PC at Itchio"
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image={ssspy} />
            <Post displayName="CaptainLobster"
                username="studio_lobster"
                verified={true}
                text="Spy Hunting Demo available for PC at Itchio"
                textc={<FaWindows onClick={()=> window.open(urlSpy, '_blank')} size={30} color='white' className='cursor-pointer'/>}
                avatar="https://pbs.twimg.com/profile_images/1636003908102283270/ZO4ql4av_400x400.jpg"
                image={chamadaspy} />
        </div>
    )
}

export default Spy